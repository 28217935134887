<template>
  <div @keypress="onKeypress" class="filters">
    <div class="pa-4">

      <p class="subtitle text-uppercase mb-3">
        Cerca avançada
        <btn
          v-if="!isCleaned"
          class="float-right"
          color="primary"
          @click="clean"
          x-small
        >
          <span class="text-none">Neteja</span>
        </btn>
      </p>

      <textfield
        v-bind="inputStyle"
        :value="lazyFilters.search"
        placeholder="Cerca per paraula clau..."
        append-icon="mdi-magnify"
        @input="setFilters({ search: $event })"
        clearable
      />

      <label class="caption">
        Per data:
      </label>

      <datefield
        :value="lazyFilters.startDate"
        v-bind="inputStyle"
        placeholder="Desde..."
        @input="setFilters({ startDate: $event })"
        cancelText="Cancelar"
        acceptText="Aplicar"
        persistent
        clearable
      />

      <datefield
        :value="lazyFilters.endDate"
        v-bind="inputStyle"
        placeholder="Fins..."
        @input="setFilters({ endDate: $event })"
        cancelText="Cancelar"
        acceptText="Aplicar"
        persistent
        clearable
      />

      <label class="caption" v-if="userID">
        Estat de publicació:
      </label>

      <selectfield
        v-if="userID"
        v-bind="inputStyle"
        :value="lazyFilters.notready_disposition"
        :items="statuses"
        @input="setFilters({ notready_disposition: $event })"
      />

      <label class="caption">
        Nº disposició:
      </label>

      <textfield
        :value="lazyFilters.num_disposition"
        v-bind="inputStyle"
        @input="setFilters({ num_disposition: $event })"
        clearable
      />

      <label class="caption">
        Nº publicació:
      </label>

      <textfield
        v-bind="inputStyle"
        :value="lazyFilters.num_publication"
        @input="setFilters({ num_publication: $event })"
        clearable
      />

      <label class="caption">
        Publicacions:
      </label>

      <selectfield
        v-bind="inputStyle"
        :value="lazyFilters.id_publications"
        :items="publications"
        item-value="id"
        @input="setFilters({ id_publications: $event })"
        clearable
      />

      <label class="caption">
        Estat:
      </label>

      <selectfield
        v-bind="inputStyle"
        :value="lazyFilters.id_states"
        :items="states"
        item-value="id"
        @input="setFilters({ id_states: $event })"
        clearable
      />

      <label class="caption">
        Tipus:
      </label>

      <selectfield
        v-bind="inputStyle"
        :value="lazyFilters.id_types"
        :items="types"
        :menu-props="{ maxHeight: 150 }"
        item-value="id"
        @input="setFilters({ id_types: $event })"
        clearable
      />

      <btn
        class="mt-4"
        color="primary"
        @click="set( lazyFilters )"
        block
      >
        Aplicar
      </btn>

    </div>
  </div>
</template>

<script>
import Btn from '@/components/Btn';
import Textfield from '@/components/inputs/Textfield';
import Selectfield from '@/components/inputs/Selectfield';
import Datefield from '@/components/inputs/Datefield';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  components: { Btn, Textfield, Selectfield, Datefield },
  data: () => ({
    lazyFilters: {},
    inputStyle: {
      backgroundColor: '#f2f2f2',
      hideDetails: true,
      outlined: true,
      filled: true
    },
    statuses: [
      { text: 'Despublicada', value: 1 },
      { text: 'Publicada', value: 0 }
    ]
  }),
  computed: {
    ...mapState( 'app', { drawerFilters: 'filters' }),
    ...mapState( 'filters', [ 'filters', 'publications', 'types', 'states' ] ),
    ...mapState( 'auth', [ 'userID' ]),
    ...mapGetters( 'filters', [ 'normalized' ]),
    isCleaned() {
      return !Object.keys( this.filters ).length;
    }
  },
  watch: {
    normalized() {
      this.lazyFilters = { ...this.filters };
    },
    drawerFilters( value ) {
      if ( ! value ) this.lazyFilters = { ...this.filters };
    },
    userID( value ) {
      !value && this.set({ notready_disposition: 0 });
    }
  },
  methods: {
    ...mapActions( 'filters', [ 'clean' ]),
    ...mapMutations( 'filters', [ 'set' ]),
    setFilters( payload ) {
      this.lazyFilters = Object.assign(
        this.lazyFilters,
        payload
      );
    },
    onKeypress(e) {
      if ( e.which === 13 ) {
        this.set( this.lazyFilters );
      }
    }
  },
  beforeMount() {
    this.lazyFilters = { ...this.filters };
  }
}
</script>
