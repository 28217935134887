<template>
  <v-hover>
    <template v-slot="{ hover }">
      <section
        class="disposition"
        :class="{ editable }"
      >

        <div v-if="editable && hover" class="disposition-acitions">

          <btn class="mr-2" color="primary" @click="$emit('click:edit')" x-small>
            Editar
          </btn>

          <btn color="primary" @click="$emit('click:delete')" x-small>
            Eliminar
          </btn>

        </div>

        <a
          v-if="!hideDate"
          tabindex="0"
          role="button"
          class="disposition-date focusable"
          @click="$emit( 'click:date', value.date_publication )"
        >
          <v-icon small v-text="'mdi-calendar-blank'"/>
          {{ date.publication }}
        </a>

        <p class="disposition-header">

          <a
            class="disposition-title focusable"
            :href="getUrl(value)"
            target="_blank"
          >
            {{ title }}
          </a>

          <btn
            v-if="state.text"
            class="focusable"
            color="primary"
            @click="$emit( 'click:state', value.id_states )"
            x-small outlined
          >
            {{ state.text }}
          </btn>

          <span v-if="date.beginning" class="body-2">
            (Entrada en vigor a partir del {{ date.beginning }})
          </span>

        </p>

        <v-alert
          v-if="parentTitle"
          class="disposition-modify"
          color="#f4f4f4"
          dense
        >
          <p>
            Aquesta disposició modifica a:
            <a :href="getUrl( value.parent )" target="_blank">{{ parentTitle }}</a>
          </p>
        </v-alert>

        <p
          class="disposition-excerpt"
          v-text="value.disposition"
        />

        <v-alert
          v-if="date.application"
          class="disposition-alert"
          color="#e2f0fa"
          dense
        >
          <p class="disposition-alert--date">
            Data d'aplicació a partir del {{ date.application }}
          </p>
          <div v-html="infoApplication"/>
        </v-alert>

        <p class="mb-2">

          <btn
            v-if="publication.text"
            color="primary"
            class="disposition-publication focusable"
            :class="{ joined: hasNumber( publication.num ) }"
            @click="$emit( 'click:publication', publication.id )"
            x-small
          >
            {{ publication.text }}
          </btn>

          <btn
            v-if="hasNumber( publication.num )"
            color="primary"
            class="disposition-number focusable"
            @click="$emit( 'click:number', publication.num )"
            x-small outlined
          >
            {{ publication.num }}
          </btn>
        </p>

        <div
          v-if="breadcrumb.length"
          class="disposition-categories mb-3"
        >

          <span style="color:#999">
            Categories:
          </span>

          <template v-for="(cat,index) in breadcrumb">
            <a
              :key="cat.id"
              tabindex="0"
              class="disposition-category focusable"
              role="button"
              @click="$emit( 'click:descriptor', cat.id )"
            >
              {{ cat.text }}
            </a>
            <span v-if="index < breadcrumb.length-1" :key="'comma-'+cat.id">, </span>
          </template>
        </div>

      </section>
    </template>
  </v-hover>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { formatDate, dispositionTitle } from '@/utils';
import Btn from '@/components/Btn';

export default {
  name: 'disposition',
  components: { Btn },
  props: {
    hideDate: Boolean,
    editable: Boolean,
    value: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    ...mapState( 'filters', [ 'publications', 'states', 'types' ]),
    ...mapState( 'categories', [ 'categories' ]),
    date() {
      const v = this.value;
      return {
        updated: v.update_disposition && formatDate( v.update_disposition.slice( 0, 10 )),
        disposition: v.date_disposition && formatDate( v.date_disposition.slice( 0, 10 )),
        publication: v.date_publication && formatDate( v.date_publication.slice( 0, 10 )),
        beginning: v.date_beginning && formatDate( v.date_beginning.slice( 0, 10 )),
        application: v.date_application && formatDate( v.date_application.slice( 0, 10 ))
      }
    },
    state() {
      return {
        text: '',
        ...this.states.find( a => a.id === this.value.id_states )
      };
    },
    type() {
      return this.getType( this.value );
    },
    publication() {
      return {
        text: '',
        num: this.value.num_publication,
        ...this.publications.find( a => a.id === this.value.id_publications )
      };
    },
    breadcrumb() {
      return this.getCategories( this.value.descriptors );
    },
    title() {
      return this.getTitle( this.value, this.type );
    },
    parentTitle() {
      return this.value.parent
        ? this.getTitle( this.value.parent )
        : null;
    },
    infoApplication() {
      if ( ! this.value.info_application ) return '';
      return this.value.info_application
        .split(/\n/).filter( Boolean ).map( paragraph => `<p>${ paragraph }</p>`).join('');
    }
  },
  methods: {
    getType( disposition ) {
      return {
        text: '',
        ...this.types.find( a => a.id === disposition.id_types )
      };
    },
    getTitle( disposition, type ) {
      return dispositionTitle( disposition, type );
    },
    getCategories( ids ) {

      if ( ! Array.isArray( ids ))
        ids = String( ids ).split(/\s*,\s*/).map( parseInt );

      return ids.map( id => this.categories.find( cat => cat.id === id ))
        .filter( Boolean );
    },
    getUrl( disposition ) {
      //https://apilegisali.uab.cat/files/
      //https://legis.inmovens.com/files/
      return disposition.file_disposition
        ? 'https://apilegisali.uab.cat/files/' + disposition.file_disposition
        : disposition.url_disposition;
    },
    hasNumber( number ) {
      return !!number && number !== '-';
    }
  }
}
</script>

<style>
.disposition {
  position: relative;
  margin-bottom: 2rem;
  max-width: 720px;
}
.disposition.editable:hover {
  outline: dashed 2px #ccc;
  outline-offset: 12px;
}
.disposition-acitions {
  position: absolute;
  top: 0;
  right: 0;
}
.disposition a:hover {
  text-decoration: underline;
}
.disposition a.disposition-date {
  color: #666666;
  font-size: 12px;
  padding: 2px 0;
  margin-bottom: 2rem;
  position: relative;
}
.disposition a.disposition-date:hover {
  text-decoration: none;
  border-bottom: 1px solid;
}
.disposition .focusable {
  position: relative;
}
.disposition .focusable:focus {
  outline: 0;
}
.disposition .focusable:focus:before {
  background-color: transparent;
}
.disposition .focusable:focus:after {
  display: block;
  content: '';
  position: absolute;
  top: 0; left: 0;
  right: 0; bottom: 0;
  box-shadow: 0 0 0 3px var(--v-primary-base);
  opacity: .25;
}
.disposition .disposition-header {
  margin: .35rem 0 .5rem 0;
}
.disposition .disposition-excerpt {
  margin-bottom: .5rem;
}
.disposition .disposition-header > * {
  margin-right: 6px;
  text-transform: none;
  vertical-align: middle;
}
.disposition .disposition-title {
  position: relative;
  color: var(--v-primary-base);
}
.disposition .disposition-publication.joined {
  border-radius: 3px 0 0 3px !important;
}
.disposition .disposition-publication.joined + .v-btn {
  border-radius: 0 3px 3px 0 !important;
}
.disposition .disposition-categories {
  color: #000;
  font-size: 13px;
}
.disposition .disposition-category {
  display: inline-block;
  margin-left: 8px;
  color: #000;
  font-size: inherit;
}
.disposition-alert {
  border: 1px solid #056095 !important;
}
.disposition-alert,
.disposition-modify {
  padding: 8px;
  font-size: 12px;
  color: #056095 !important;
}
.disposition-alert p,
.disposition-modify p {
  margin-bottom: 2px;
}
.disposition-alert .disposition-alert--date {
  display: inline-block;
  margin-bottom: 4px;
  background-color: #a6d2f1;
  border-radius: 3px;
  padding: 3px 7px;
  font-size: 11px;
}
.disposition-alert .v-alert__icon.v-icon {
  color: inherit;
}
</style>
