<template>
  <div v-resize="onResize">

    <v-progress-linear
      class="progress"
      :active="loading"
      :style="`top:${headerHeight}px`"
      color="primary"
      indeterminate absolute
    />

    <div
      v-if="loading"
      class="app-block"
      :style="`top:${headerHeight}px`"
    />

    <v-navigation-drawer
      tag="aside"
      :width="categoriesWidth"
      :value="drawerCategories"
      :permanent="drawerCategories && !mobile"
      :temporary="mobile"
      @input="openCategories"
      clipped app
    >

      <categories id="categories"/>

    </v-navigation-drawer>

    <v-navigation-drawer
      tag="aside"
      width="300"
      :value="drawerFilters"
      :permanent="drawerFilters && !mobile"
      :temporary="mobile"
      @input="openFilters"
      clipped right app
    >

      <filters
        id="filters"
        class="fill-height pt-2"
      />

    </v-navigation-drawer>

    <v-app-bar
      id="sub-header"
      :height="headerHeight"
      :style="`top:${headerHeight}px`"
      color="white"
      flat app
    >
      <btn
        class="mr-2"
        :color="drawerCategories ? 'primary':'dark'"
        :title="drawerCategories ? 'Tanca les categories':'Obre les categories'"
        @click="openCategories(!drawerCategories)"
        icon
      >
        <v-icon v-text="drawerCategories ? 'mdi-menu-open':'mdi-menu'"/>
      </btn>

      <h2 class="body-1 dark--text mb-0">
        Resultats <span class="caption">({{ total }})</span>
      </h2>

      <v-spacer/>

      <btn
        color="dark"
        title="Exportar resultats"
        :loading="downloading"
        @click="exportData"
        icon
      >
        <v-icon v-text="'mdi-download'"/>
      </btn>

      <btn
        color="dark"
        title="Imprimir resultats"
        :loading="printing"
        @click="printData"
        icon
      >
        <v-icon v-text="'mdi-printer'"/>
      </btn>

      <btn
        class="text-none"
        :color="drawerFilters ? 'primary':'dark'"
        :title="drawerFilters ? 'Tanca la cerca avançada':'Obre la cerca avançada'"
        @click="openFilters(!drawerFilters)"
        :text="showFilterTitle"
        :icon="!showFilterTitle"
      >
        <v-icon :class="{'mr-2':showFilterTitle}" v-text="'mdi-magnify'"/>
        {{ showFilterTitle ? 'Cerca avançada' : '' }}
      </btn>
    </v-app-bar>

    <v-app-bar
      ref="subHeader"
      class="sub-header-2"
      :class="{ '--is-mobile': mobile }"
      :height="subHeaderHeight2"
      :style="`top:${headerHeight*2}px`"
      color="white"
      flat app
    >

      <selectfield
        class="pb-3 mr-2"
        style="width:200px"
        label="Ordenar resultats per:"
        height="24"
        :value="orderby"
        :items="orderItems"
        @input="setOrder({ orderby: $event, order })"
        hide-details
        single-line
        outlined
      />

      <btn
        height="24"
        title="Ordernar resultats de forma ascendent"
        :color="order == 'asc' ? 'primary' : '#EEE'"
        @click="setOrder({ orderby, order: 'asc' })"
        x-small tile dark
      >
        <v-icon small v-text="'mdi-chevron-up'"/>
      </btn>

      <btn
        height="24"
        class="mr-6"
        title="Ordernar resultats de forma descendent"
        :color="order == 'desc' ? 'primary' : '#f4f4f4'"
        @click="setOrder({ orderby, order: 'desc' })"
        x-small tile dark
      >
        <v-icon small v-text="'mdi-chevron-down'"/>
      </btn>

      <selectfield
        v-show="!drawerCategories && selected.length"
        class="pb-3 mr-6"
        style="width:200px"
        label="Categories seleccionades:"
        height="24"
        item-value="id"
        item-text="text"
        :value="lazySelected"
        :items="selectedCategories"
        @input="setSelected( $event || [] )"
        hide-details
        single-line
        clearable
        multiple
        outlined
      >
        <template v-slot:items="cs_scope">
          <v-item
            v-for="( item, index ) in cs_scope.items"
            v-slot="{ active, toggle }"
            :key="item.value"
            :value="item"
          >
            <div
              class="selectfield__item white"
              :class="{['v-item--active']: active }"
              v-bind="cs_scope.attrs"
              v-on="cs_scope.on"
            >
              <div class="grey--text">
                {{ item.text }}
              </div>
              <v-spacer/>
              <v-icon
                color="black"
                v-text="'$close'"
                @click="toggle"
                x-small
              />
            </div>
          </v-item>
        </template>
      </selectfield>

      <selectfield
        v-show="!drawerFilters && hasFilters"
        class="pb-3"
        style="width:200px"
        label="Filtres:"
        height="24"
        item-value="value"
        :value="lazyFilters"
        :items="listFilters"
        @input="onInputFilters"
        return-object
        hide-details
        single-line
        clearable
        multiple
        outlined
      >
        <template v-slot:items="fs_scope">
          <v-item
            v-for="( item, index ) in fs_scope.items"
            v-slot="{ active, toggle }"
            :key="item.value"
            :value="item"
          >
            <div
              class="selectfield__item white"
              :class="{['v-item--active']: active }"
              v-bind="fs_scope.attrs"
              v-on="fs_scope.on"
            >
              <div class="grey--text">
                <span class="black--text">{{ item.text }}:</span> {{ item.value }}
              </div>
              <v-spacer/>
              <v-icon
                color="black"
                v-text="'$close'"
                @click="toggle"
                x-small
              />
            </div>
          </v-item>
        </template>
      </selectfield>

    </v-app-bar>

    <v-main id="main" :style="`padding-top:${ headerHeight*2 + subHeaderHeight2 }px`">
      <div class="dispositions">

        <disposition
          v-for="disposition in results"
          :key="disposition.id"
          :value="disposition"
          :editable="isEditable"
          @click:date="selectDate"
          @click:state="selectState"
          @click:publication="selectPublication"
          @click:number="selectNumber"
          @click:descriptor="selectDescriptor"
          @click:edit="editItem(disposition)"
          @click:delete="onRemove(disposition)"
        />

        <infinite-scroll :identifier="infiniteID" @infinite="loadPage">

          <v-progress-circular
            slot="spinner"
            class="ma-4"
            color="grey"
            size="30"
            width="3"
            indeterminate
          />

          <div slot="error" slot-scope="{ trigger }">
            <p>Error al cargar :(</p>
            <btn color="primary" @click="trigger" small>
              Reintentar
            </btn>
          </div>

          <div slot="no-more">
            <v-divider/>
          </div>

          <div slot="no-results">

          </div>

        </infinite-scroll>
      </div>
    </v-main>

    <v-scale-transition>
      <btn
        v-if="userID"
        class="btn-new-disposition"
        :class="{ 'to-left': drawerFilters && !mobile }"
        elevation="5"
        color="primary"
        @click="editItem()"
      >
        Nova disposició
      </btn>
    </v-scale-transition>

    <dispositions-dialog
      v-if="userID"
      v-model="dialog"
      ref="dialog"
      :data="model"
      :title="dialogTitle"
      :is-new="isNew"
      @submit="reset"
      @removed="reset"
    />

    <edit-dialog
      v-model="deleteDialog"
      accept-text="Si"
      cancel-text="No"
      max-width="340"
      hide-button-close
      persistent
      title="Alerta d'eliminació de contingut"
      @click:save="remove(model)"
    >

      <div class="black--text pa-4">
        <p>Al eliminar un element s'esborra de la base de dades de tal manera que no pot ser recuperable.</p>
        <p ><strong>Està segur que dessitja continuar?</strong></p>
      </div>

    </edit-dialog>

  </div>
</template>

<script>
import moment from 'moment';
import { deepEqual } from 'vuetify/lib/util/helpers';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import Btn from '@/components/Btn';
import Filters from '@/components/Filters';
import Categories from '@/components/Categories';
import EditDialog from '@/components/Dialog';
import Disposition from '@/components/Disposition';
import DispositionsDialog from '@/components/DispositionsDialog';
import InfiniteScroll from 'vue-infinite-loading';
import Selectfield from '@/components/inputs/Selectfield';

export default {
  name: 'Home',
  components: {
    Btn,
    Filters,
    Categories,
    EditDialog,
    Disposition,
    DispositionsDialog,
    InfiniteScroll,
    Selectfield
  },
  data: () => ({
    model: {},
    isNew: false,
    dialog: false,
    deleteDialog: false,
    dialogTitle: 'Editar disposició',
    innerWidth: 0,
    subHeaderHeight2: 50,
    lazySelected: [],
    lazyFilters: [],
    filterNames: {
      search: 'Búsqueda',
      startDate: 'Data inici',
      endDate: 'Data final',
      id_publications: 'Publicació',
      num_disposition: 'Nº Disposició',
      num_publication: 'Nº Publicació',
      id_states: 'Estat',
      id_types: 'Tipus'
    },
    orderItems: [
      { value: 'date_disposition', text: 'Data de disposició' },
      { value: 'date_publication', text: 'Data de publicació' },
      { value: 'num_disposition',  text: 'Numero de disposició' },
      { value: 'publications',     text: 'Publicació' },
      { value: 'types',            text: 'Tipus' },
      { value: 'states',           text: 'Estat' },
      // { value: 'num_publication',  text: 'Numero de publicació' },
    ]
  }),
  computed: {
    ...mapState([
      'results',    'total',
      'loading',    'downloading',
      'printing',   'page',
      'infiniteID', 'orderby',
      'order'
    ]),
    ...mapState( 'auth', [ 'userID' ]),
    ...mapState( 'categories', [ 'selected', 'categories' ]),
    ...mapState( 'filters', [ 'publications', 'states', 'types' ]),
    ...mapGetters( 'filters', { filters: 'normalized' }),
    ...mapState( 'app', {
      headerHeight: 'headerHeight',
      drawerCategories: 'categories',
      drawerFilters: 'filters'
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showFilterTitle() {
      return !this.drawerFilters && !this.mobile;
    },
    isEditable() {
      return !!this.userID;
    },
    hasFilters() {
      return !!this.listFilters.length;
    },
    listFilters() {
      return Object.keys( this.filters ).map(( key, value ) => {

        if ( key === 'notready_disposition' ) return;
        value = this.filters[key];

        if ([ 'startDate', 'endDate' ].includes( key )) {
          value = moment( value ).format('DD/MM/YYYY');
        } else if ( key === 'id_publications' ) {
          value = this.publications.find( p => p.id === value );
          if ( value ) value = value.text;
          else return;
        } else if ( key === 'id_states' ) {
          value = this.states.find( p => p.id === value );
          if ( value ) value = value.text;
          else return;
        } else if ( key === 'id_types' ) {
          value = this.types.find( p => p.id === value );
          if ( value ) value = value.text;
          else return;
        }

        return { text: this.filterNames[key], key, value };
      }).filter( Boolean );
    },
    selectedCategories() {
      return this.selected
        .map( id => this.categories.find( a => a.id === id ))
        .filter( Boolean );
    },
    categoriesWidth() {
      return this.innerWidth && this.innerWidth > 360 ? 360 : 300;
    }
  },
  watch: {
    selected( value ) {
      this.$nextTick(() => this.lazySelected = value.slice());
      this.onResize();
      this.reset();
    },
    listFilters( value ) {
      this.$nextTick(() => this.lazyFilters = value.slice());
    },
    'orderby': 'reset',
    'order': 'reset',
    userID( value ) {
      !value && ( this.dialog = false );
    },
    filters( value, oldValue ) {
      if ( ! deepEqual( value, oldValue )) {
        this.onResize();
        this.reset();
      }
    },
    mobile( value ) {
      this.openCategories( !value );
      this.openFilters( false );
    },
  },
  methods: {
    ...mapActions([ 'reset', 'fetch' ]),
    ...mapMutations({ setResults: 'set', setPage: 'setPage', setOrder: 'setOrder' }),
    ...mapMutations( 'filters', { setFilters: 'set' }),
    ...mapMutations( 'categories', [ 'setSelected' ]),
    ...mapMutations( 'app', {
      openCategories: 'setCategories',
      openFilters: 'setFilters',
      setAlert: 'setAlert'
    }),
    loadPage( state ) {
      this.fetch().then( r => {
        this.setPage( this.page + 1 );
        if ( r.length < 20 ) state.complete();
        else state.loaded();
      }).catch( err => {
        this.setAlert({ type: 'error', message: err.message });
        state.error();
      });
    },
    exportData() {
      this.$store.dispatch('exportData');
    },
    printData() {
      this.$store.dispatch('printData');
    },
    selectDate( startDate ) {
      startDate = new Date(startDate).getTime();
      this.setFilters({ startDate });
    },
    selectPublication( id_publications ) {
      this.setFilters({ id_publications });
    },
    selectNumber( num_publication ) {
      this.setFilters({ num_publication });
    },
    selectState( id_states ) {
      this.setFilters({ id_states });
    },
    selectDescriptor( id ) {
      this.setSelected([ id ]);
    },
    editItem( model ) {
      this.isNew = !model;
      this.model = model || { date_publication: new Date(), notready_disposition: 1 };
      this.dialogTitle = model ? 'Editar disposició' : 'Nova disposició';
      this.$refs.dialog && this.$refs.dialog.resetValidation();
      this.dialog = true;
    },
    onInputFilters( value ) {
      const set = {};
      value = value || [];
      this.listFilters.forEach( item => {
        if ( ! value.find( v => v.key === item.key ))
          set[item.key] = null;
      });
      this.setFilters( set );
    },
    onRemove( model ) {
      this.model = model;
      this.deleteDialog = true;
    },
    onResize() {
      this.innerWidth = window && window.innerWidth || 0;
      this.subHeaderHeight2 = 50;
      this.$nextTick(() => {
        requestAnimationFrame(() => {
          const { subHeader } = this.$refs;
          this.subHeaderHeight2 = subHeader ? subHeader.$el.scrollHeight : 50;
        });
      });
    },
    remove( model ) {
      this.deleteDialog = false;
      this.$refs.dialog && this.$refs.dialog.remove( model );
    }
  }
}
</script>

<style>
.btn-new-disposition {
  position: fixed;
  right: 16px;
  bottom: 16px;
  transition: right 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-new-disposition.to-left {
  right: 316px;
}
.sub-header-2 > div {
  flex-wrap: wrap;
  border-bottom: 1px solid #f4f4f4;
}
.sub-header-2:not(.--is-mobile)  > div {
  padding-left: 60px !important;
}
</style>
