<template>
  <div class="home">

    <v-progress-linear
      class="progress"
      :active="loading"
      :style="`top:${headerHeight}px`"
      color="primary"
      indeterminate absolute
    />

    <div
      v-if="loading"
      class="app-block"
      :style="`top:${headerHeight}px`"
    />

    <v-main
      id="main"
      class="tab-section"
      data-up="sub-header"
      data-left="categories"
      data-right="filters"
      :style="`padding-top:${headerHeight}px`"
    >
      <div class="dispositions">

        <div class="home-actions">

          <btn
            class="mr-3"
            color="dark"
            title="Exportar resultats"
            :loading="downloading"
            @click="exportData"
            icon
          >
            <v-icon v-text="'mdi-download'"/>
          </btn>

          <btn
            class="mr-3"
            color="dark"
            title="Imprimir resultats"
            :loading="printing"
            @click="printData"
            icon
          >
            <v-icon v-text="'mdi-printer'"/>
          </btn>

          <btn
            class="btn-search text-none"
            color="black"
            title="Obre la cerca avançada"
            @click="openSearch"
            :text="showFilterTitle"
            :icon="!showFilterTitle"
          >
            <v-icon :class="{'mr-2':showFilterTitle}" v-text="'mdi-magnify'"/>
            {{ showFilterTitle ? 'Cerca avançada' : '' }}
          </btn>
        </div>

        <h2 class="black--text">Novetats</h2>

        <template v-if="news && news.length">

          <p class="caption">
            Disposicions dels últims 30 dies
          </p>

          <template v-for="(section,i) in sections">

            <p class="my-6 header" :key="`p-${i}`">
              <v-icon>mdi-calendar-blank</v-icon>
              Disposicions del <strong>{{ section.date }}</strong>
            </p>

            <v-card
              v-for="disposition in section.items"
              :key="disposition.id"
              width="100%"
              max-width="720"
              class="mb-4 pa-4"
            >
              <disposition
                :value="disposition"
                hide-date
                @click:date="selectDate"
                @click:state="selectState"
                @click:publication="selectPublication"
                @click:number="selectNumber"
                @click:descriptor="selectDescriptor"
              />
            </v-card>

          </template>
        </template>
      </div>
    </v-main>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Disposition from '@/components/Disposition';
import Btn from '@/components/Btn';

export default {
  name: 'News',
  components: { Disposition, Btn },
  computed: {
    ...mapState([ 'loading', 'downloading', 'printing', 'news' ]),
    ...mapState( 'app', [ 'headerHeight' ]),
    sections() {

      var group, date, d;
      const sections = [];

      this.news
        .sort(( a, b ) => new Date( b.date_publication ) - new Date( a.date_publication ))
        .forEach( item => {
          d = item.date_publication.slice( 0, 10 );
          if ( d === date ) group.items.push( item );
          else {
            date = d;
            sections.push(( group = {
              date: d.split('-').reverse().join('/'),
              items: [ item ]
            }));
          }
        });

      return sections;
    },
    showFilterTitle() {
      return !this.drawerFilters && !this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapMutations( 'filters', { setFilters: 'set' }),
    ...mapMutations( 'categories', [ 'setSelected' ]),
    ...mapMutations( 'app', { openCategories: 'setCategories', openFilters: 'setFilters' }),
    ...mapActions([ 'reset' ]),
    selectDate( startDate ) {
      this.openFilters(true);
      this.setFilters({ startDate: new Date(startDate).getTime() });
      this.reset();
      this.$router.push('/disposicions');
    },
    selectPublication( id_publications ) {
      this.openFilters(true);
      this.setFilters({ id_publications });
      this.$router.push('/disposicions');
    },
    selectNumber( num_publication ) {
      this.openFilters(true);
      this.setFilters({ num_publication });
      this.reset();
      this.$router.push('/disposicions');
    },
    selectState( id_states ) {
      this.openFilters(true);
      this.setFilters({ id_states });
      this.reset();
      this.$router.push('/disposicions');
    },
    selectDescriptor( id ) {
      this.openCategories( true );
      this.setSelected([ id ]);
      this.reset();
      this.$router.push('/disposicions');
    },
    openSearch() {
        this.openFilters(true);
        this.$router.push('/disposicions');
    },
    exportData() {
      this.$store.dispatch( 'exportHomeData' );
    },
    printData() {
      this.$store.dispatch( 'printHomeData' );
    }
  },
  created() {
    if ( ! this.news )
      this.$store.dispatch( 'fetch', { news: true });
  }
}
</script>

<style>
.home .disposition-excerpt {
  max-height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.home .header {
  color: black;
}
.home .home-actions {
  position: absolute;
  top: 12px;
  right: 8px;
}
</style>
